import React from 'react';
import {Show, SimpleShowLayout, TextField} from 'react-admin';

const PackageCalorieShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="amount" />
            </SimpleShowLayout>
        </Show>
    );
};

export default PackageCalorieShow;