import React from 'react';
import {DateField, NumberField, Show, SimpleShowLayout, TextField} from 'react-admin';

const MealCategoryShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="name" />
                <NumberField source="importance" title="Importance" />
                <DateField source="updatedAt" locales={"fa-IR"}/>
                <DateField source="createdAt" locales={"fa-IR"}/>
            </SimpleShowLayout>
        </Show>
    );
};

export default MealCategoryShow;