import React from 'react';
import {Create} from 'react-admin';
import RecipeForm from "./RecipeForm";
import resource from "../../config/resource";
import usePermission from "../../components/permission/usePermission";

const RecipeCreate = (props) => {
    const hasPermission = usePermission();
    const onlyHasRecipeAdminPermission = !hasPermission(`${resource.recipe}.edit.status.base`)
    return (
            <Create {...props} transform={data => ({...data,status:onlyHasRecipeAdminPermission ? 'nutritionVerification' : data.status})}>
               <RecipeForm/>
            </Create>
    );
};

export default RecipeCreate;