import React from 'react';
import {Datagrid, List, TextField} from 'react-admin';

const PackageCalorieList = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick="show">
                <TextField source="amount" />
            </Datagrid>
        </List>
    );
};

export default PackageCalorieList;