import React from 'react';
import {Toolbar,SaveButton} from "react-admin";

const EditToolbar = (props) => {
    return (
        <Toolbar {...props}>
            <SaveButton disabled={props.pristine}/>
        </Toolbar>
    );
};

export default EditToolbar;