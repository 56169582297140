import React from 'react';
import {Tab} from "react-admin";
import WithPropsAndLabels from "../WithPropsAndLabels";

const CustomTab = ({children,label,...props}) => {
    return (
        <Tab label={label} {...props}>
            <WithPropsAndLabels>
                {children}
            </WithPropsAndLabels>
        </Tab>
    );
};

export default CustomTab;