import React from 'react';
import {BooleanField, Datagrid, DateField, List, NumberField, TextField} from 'react-admin';

const MealCategoryList = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick="show">
                <TextField source="name" />
                <NumberField source="importance" />
                <DateField source="updatedAt" locales={"fa-IR"}/>
                <DateField source="createdAt" locales={"fa-IR"}/>
                <BooleanField addLabel source="deleted" />
            </Datagrid>
        </List>
    );
};

export default MealCategoryList;