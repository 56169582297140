import React, { useState } from "react";
import { getResources, Menu as RaMenu, MenuItemLink } from "react-admin";
import { useSelector } from "react-redux";
import DefaultIcon from "@material-ui/icons/ViewList";
import resource from "../../config/resource";
import SubMenu from "./SubMenu";
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
import LocalDiningIcon from "@material-ui/icons/LocalDining";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import TipsIcon from "@material-ui/icons/Flag";
import QueueIcon from '@material-ui/icons/Queue';

const Menu = () => {

  const [openMenuKey, setOpenMenuKey] = useState(null);
  const onOpen = (key) => {
    setOpenMenuKey((openMenuKey) => (openMenuKey === key ? null : key));
  };
  const resources = useSelector(getResources);
  const getResource = (resourceName) => {
    return resources.filter((resource) => resourceName === resource.name)[0];
  };
  const resourceMenu = [
    {
      ...getResource(resource.user),
      icon: <PeopleAltRoundedIcon />,
      label: "کاربران",
    },
    {
      label: "رژیم",
      icon: <LocalDiningIcon />,
      children: [
        {
          ...getResource(resource.diet),
          label: "Diet",
        },
        {
          ...getResource(resource.dietType),
          label: "Diet Type",
        },
        // {
        //     ...getResource(resource.predefinedDiet),
        //     label:'Predefined Diet'
        // },
        {
          ...getResource(resource.dietTypeCategory),
          label: "Diet Type Category",
        },
        {
          ...getResource(resource.specialType),
          label: "Special Type",
        },
      ],
    },
    {
      label: "غذا",
      icon: <RestaurantIcon />,
      children: [
        {
          ...getResource(resource.food),
          label: "Food",
        },
        {
          ...getResource(resource.foodTag),
          label: "Food Tag",
        },
        {
          ...getResource(resource.package),
          label: "Package",
        },
        {
          ...getResource(resource.foodUnit),
          label: "Food Unit",
        },
        {
          ...getResource(resource.hatedFood),
          label: "Hated Food",
        },
        {
          ...getResource(resource.packageCalorie),
          label: "Package Calorie",
        },
        {
          ...getResource(resource.mealCategory),
          label: "Meal Category",
        },
        {
          ...getResource(resource.disease),
          label: "Disease",
        },
        {
          ...getResource(resource.category),
          label: "Category",
        },
        {
          ...getResource(resource.copyFoodData),
          label: "food Add To Food",
        },
      ],
    },
    {
      label: "دستور پخت",
      icon: <FastfoodIcon />,
      children: [
        {
          ...getResource(resource.recipe),
          label: "Recipe",
        },
        {
          ...getResource(resource.recipeCategory),
          label: "Recipe Category",
        },
      ],
    },
    {
      ...getResource(resource.groceryCategory),
      icon: <ShoppingCart />,
      label: "دسته‌بندی خرید",
    },
    {
      label: "کلاس ورزشی",
      icon: <FitnessCenterIcon />,
      children: [
        {
          ...getResource(resource.exerciseInstructionTagCategory),
          label: "دسته بندی تگ",
        },
        {
          ...getResource(resource.exerciseInstructionTag),
          label: "تگ",
        },
        {
          ...getResource(resource.exerciseInstructionCategory),
          label: "دسته بندی ورزش",
        },
        {
          ...getResource(resource.exerciseInstruction),
          label: "ورزش",
        },
      ],
    },
    {
      ...getResource(resource.tip),
      icon: <TipsIcon />,
      label: "توصیه ها و راهنمایی ها",
    },
    {
        ...getResource(resource.copyFoodData),
        icon: <QueueIcon />,
        label: "اضافه کردن غذا به غذا",
      },
  ];

  const hasAccessToSubmenu = (item) => {
    for (let child of item.children) {
      if (child.hasList) return true;
    }
    return false;
  };

  return (
    <RaMenu>
      {resourceMenu.map((item, index) =>
        item.children
          ? hasAccessToSubmenu(item) && (
              <SubMenu
                open={openMenuKey === item.label}
                onOpen={onOpen}
                key={index}
                item={item}
              />
            )
          : item.hasList && (
              <MenuItemLink
                key={index}
                to={`/${item.name}`}
                primaryText={item.label || item.name}
                leftIcon={item.icon || <DefaultIcon />}
              />
            )
      )}
    </RaMenu>
  );
};

export default Menu;
