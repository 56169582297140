import React from 'react';
// import {Form} from 'react-final-form';
import {
    // CardContentInner,
    // sanitizeEmptyValues,
    // SaveButton,
    SimpleForm,
    // Toolbar,
    // useNotify,
    // useTranslate
} from 'react-admin';
// import arrayMutators from 'final-form-arrays';
import WithPropsAndLabels from "../WithPropsAndLabels";
import {useForm,useFormState} from "react-final-form";

// const CustomForm = ({save, saving, version, children,redirect,validate,toolbar,...props}) => {
//     console.log(saving,save,version,redirect,validate,props)
//     const notify = useNotify();
//     const translate = useTranslate();
//     const submit = (values,form) => {
//         save(sanitizeEmptyValues(props.record, values),redirect);
//     };
//     return (
//         <Form
//             initialValues={props.record}
//             onSubmit={submit}
//             mutators={{...arrayMutators}} // necessary for ArrayInput
//             subscription={defaultSubscription} // don't redraw entire form each time one field changes
//             key={version} // support for refresh button
//             keepDirtyOnReinitialize
//             validate={validate}
//             render={formProps => {
//                 const handleSubmit = () => {
//                     if (formProps.form.getState().invalid) {
//                         notify(translate('ra.message.invalid_form'), "error");
//                     }
//                     formProps.handleSubmit()
//                 }
//                 return (
//                     <>
//                         <CardContentInner>
//                             <WithPropsAndLabels {...props} formValues={formProps.values}>
//                                 {typeof children==='function' ? children(formProps) : children}
//                             </WithPropsAndLabels>
//                         </CardContentInner>
//                         {toolbar ? toolbar({saving:formProps.submitting,disabled:formProps.pristine,handleSubmit:handleSubmit}) : toolbar ?? <Toolbar>
//                             <SaveButton saving={formProps.submitting} disabled={formProps.pristine}
//                                         handleSubmitWithRedirect={handleSubmit}/>
//                         </Toolbar>}
//                     </>
//
//                 )
//             }}
//         />
//     );
// };
// const defaultSubscription = {
//     submitting: true,
//     pristine: true,
//     valid: true,
//     invalid: true,
//     values:true
// };

const CustomForm = ({children,save,saving,redirect,initialValues,toolbar,...props}) => {
    return (
        <SimpleForm save={save} saving={saving} redirect={redirect} initialValues={initialValues} toolbar={toolbar} {...props}>
          <FormBody>
              {children}
          </FormBody>
        </SimpleForm>
    )
}
const FormBody = (props) => {
    const form = useForm();
    const formState = useFormState();
    return (
        <WithPropsAndLabels {...props}>
            {typeof props.children ==='function' ? props.children(formState,form) : props.children}
        </WithPropsAndLabels>
    )
}
// function removeNullValues(values){
//     const res =  Object.keys(values).reduce((acc,key) => {
//         if(values[key]!=null){
//             acc[key]=values[key]
//         }
//         return acc;
//     },{})
//     console.log(res);
//     return res;
// }

export default CustomForm;