const resource = {
  user: "user",
  dietType: "dietType",
  dietTypeCategory: "dietTypeCategory",
  specialType: "specialType",
  recipeCategory: "recipeCats",
  foodTag: "foodTag",
  foodUnit: "foodUnit",
  translations: "translations",
  hatedFood: "hatedFood",
  packageCalorie: "packageCalorie",
  mealCategory: "mealCategory",
  disease: "disease",
  package: "package",
  role: "role",
  food: "food",
  recipe: "recipe",
  groceryCategory: "groceryCategory",
  category: "category",
  exerciseInstructionTagCategory: "exerciseInstructionTagCategory",
  exerciseInstructionTag: "exerciseInstructionTag",
  exerciseInstructionCategory: "exerciseInstructionCategory",
  exerciseInstruction: "exerciseInstruction",
  exercise: "exercise",
  tip: "tip",
  copyFoodData: "copyFoodData",
};
export default resource;
