import get from "lodash/get";
import {permissions} from "../../config/permissions";

const usePermission = () => {

    const userRoles = localStorage.getItem("roles")?.split(',');

    function hasPermission(resourceKey) {
        const resourceAuthorizedRoles = get(permissions, resourceKey);
        if (!resourceAuthorizedRoles) {
            return true;
        }
        return resourceAuthorizedRoles.some(authorizedRole => userRoles?.includes(authorizedRole));
    }

    return hasPermission;
};

export default usePermission;