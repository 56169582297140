import React from 'react';
import {Edit} from "react-admin";
import PackageForm from "./PackageForm";

const PackageEdit = (props) => {
    return (
        <Edit {...props}>
            <PackageForm/>
        </Edit>
    );
};


export default PackageEdit;