import React from 'react';
import {
    ArrayField,
    BooleanField, ChipField,
    ReferenceArrayField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    SingleFieldList,
    TextField
} from 'react-admin';
import resource from "../../config/resource";

const PackageShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="meal" label="meal" />
                <ReferenceField
                    source="category"
                    reference={resource.mealCategory}
                    label="Category"
                    allowEmpty
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceArrayField
                    source="types"
                    reference={resource.dietType}
                    allowEmpty
                >
                    <SingleFieldList>
                        <ChipField className="packageFoods" source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ArrayField source="foods">
                    <SingleFieldList>
                        <ReferenceField source="foodId" reference={resource.food} label="Food Name">
                            <ChipField className="packageFoods" source="name" />
                        </ReferenceField>
                    </SingleFieldList>
                </ArrayField>
                <BooleanField addLabel source="recommended" label="is recommended"/>
                <BooleanField addLabel source="deleted" label="is deleted"/>
            </SimpleShowLayout>
        </Show>
    );
};

export default PackageShow;