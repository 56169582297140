import React from 'react';
import {Paper,Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import get from 'lodash/get';

const Table = ({records,columns}) => {
    const getValue = (value) => {
        if(Number.isNaN(value) || value==='NaN')
            return "";
        return value;
    }
    return (
        <TableContainer component={Paper}>
            <MuiTable>
                <TableHead>
                    <TableRow>
                        {columns?.map(col => <TableCell align={"left"} key={col.label}>{col.label || col.key}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records?.map((record, index) => (
                        <TableRow key={index}>
                            {columns.map((col) => <TableCell key={col.label} align="left">{col.render ? col.render(get(record,col.key),record) : getValue(get(record,col.key))}</TableCell>)}
                        </TableRow>
                    ))}
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
};

export default Table;