import React from 'react';
import {BooleanField, Show, SimpleShowLayout, TextField} from 'react-admin';

const HatedFoodShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <BooleanField addLabel source="deleted" />
                <TextField source="name" />
            </SimpleShowLayout>
        </Show>
    );
};

export default HatedFoodShow;