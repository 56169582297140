import React from 'react';
import {SimpleFormIterator} from "react-admin";
import WithPropsAndLabels from "../WithPropsAndLabels";

const CustomFormIterator = ({children,...props}) => {
    return (
        <SimpleFormIterator {...props}>
            <WithPropsAndLabels>
                {children}
            </WithPropsAndLabels>
        </SimpleFormIterator>
    );
};

export default CustomFormIterator;