import React from 'react';
import {
    BooleanField,
    ChipField,
    Datagrid,
    ImageField,
    List,
    ReferenceArrayField,
    ReferenceField,
    SingleFieldList,
    TextField
} from 'react-admin';
import resource from "../../config/resource";

const DietTypeList = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick="show">
                <ImageField source="image" label="Image" />
                <TextField source="name" />
                <ReferenceField source="dietTypeCategory" reference={resource.dietTypeCategory} >
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="days" />
                <ReferenceArrayField
                    source="packageCalories"
                    reference={resource.packageCalorie}
                    label="Calorie Amount Steps"
                >
                    <SingleFieldList linkType={false}>
                        <ChipField source="amount" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <BooleanField source="completed" label="completed"/>
            </Datagrid>
        </List>
    );
};

export default DietTypeList;