import React from 'react';
import {Create} from 'react-admin';
import FoodForm from "./FoodForm";

const FoodCreate = (props) => {
    return (
        <Create {...props}>
            <FoodForm/>
        </Create>
    );
};

export default FoodCreate;