import Service from "./Service";

class PackageService extends Service{
    transformRequest(req) {

        return {
            ...req,
            calorieSizes:req.calorieSizes.map(calorieSize => ({...calorieSize,sizes:fixSizes(calorieSize.sizes)}))
        }
        function fixSizes(sizes){
            return sizes?.slice(0,req.foods.length)
        }
    }
}

export default PackageService;