import {httpClient} from "../config/client";
import Service from "./Service";


class DietTypeService extends Service{
    addType(params){
        return httpClient({URL:`${this.URL}/${params.id}/addType`,method:'PUT',data:params})
    }
    delType(params){
        return httpClient({URL:`${this.URL}/${params.id}/delType`,method:'PUT',data:params})
    }
}
export default DietTypeService;