import React from 'react';
import {Resource} from "react-admin";
import usePermission from "./usePermission";


const AuthorizedResource = ({name,list,show,edit,create,options,icon,...props}) => {
    const hasPermission = usePermission();

    return (
        <Resource name={name}
                  list={hasPermission(`${name}.list.base`) && list}
                  show={hasPermission(`${name}.show.base`) && show}
                  edit={hasPermission(`${name}.edit.base`) && edit}
                  create={hasPermission(`${name}.create.base`) && create}
                  icon={icon} options={options} {...props}/>
    );
};

export default AuthorizedResource;