import React from 'react';
import GroceryForm from "./GroceryForm";
import {Create} from "react-admin";

const GroceryCategoryCreate = (props) => {
    return (
        <Create {...props}>
            <GroceryForm/>
        </Create>
    );
};

export default GroceryCategoryCreate;