import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    DateField
} from 'react-admin';
import resource from "../../config/resource";

const GroceryCategoryList = (props) => {
    return (
        <List {...props} sort={{field:'createdAt',order:'DESC'}}>
            <Datagrid rowClick={"show"}>
                <TextField source={"name"}/>
                <TextField source={"order"}/>
                <ReferenceArrayField source={"tags"} reference={resource.foodTag}>
                    <SingleFieldList>
                        <ChipField source="name"/>
                    </SingleFieldList>
                </ReferenceArrayField>
                <DateField locales={"fa-IR"} source={"createdAt"}/>
                <DateField locales={"fa-IR"} source={"updatedAt"}/>
                <BooleanField addLabel source={"deleted"}/>
            </Datagrid>
        </List>
    );
};

export default GroceryCategoryList;