import React from 'react';
import {Edit, NumberInput, SimpleForm, TextInput} from 'react-admin';

const MealCategoryEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="show">
                <TextInput source="name" />
                <NumberInput source="importance" />
            </SimpleForm>
        </Edit>
    );
};

export default MealCategoryEdit;