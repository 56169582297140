import React from 'react';
import {BooleanField, Datagrid, DateField, List, TextField} from 'react-admin';

const DiseaseList = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick="show">
                <TextField source="name" />
                <DateField source="updatedAt" locales={"fa-IR"}/>
                <BooleanField addLabel source="deleted" />
            </Datagrid>
        </List>
    );
};

export default DiseaseList;