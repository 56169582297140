import React from 'react';
import {Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles} from "@material-ui/core";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import DefaultIcon from "@material-ui/icons/ViewList";
import {MenuItemLink} from "react-admin";

const useStyles = makeStyles((theme) => {
    return ({
        nested: {
            paddingLeft: theme.spacing(7),
            ...theme.typography.body2
        },
    })
});

const SubMenu = ({item,open,onOpen}) => {
    const classes = useStyles();
    const handleClick = () => {
        onOpen(item.label);
    }
    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemIcon>
                    {item.icon || <DefaultIcon/>}
                </ListItemIcon>
                <ListItemText primary={item.label}/>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {item.children.map((child,index) => {
                       return ( child.hasList && <MenuItemLink
                            className={classes.nested}
                            key={index}
                            to={`/${child.name}`}
                            primaryText={
                                child.label || child.name
                            }
                            // leftIcon={
                            //     child.icon ? <resource.icon/> : <DefaultIcon/>
                            // }
                        />)
                    })}
                </List>
            </Collapse>
        </>
    );
};

export default SubMenu;