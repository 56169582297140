import React from 'react';
import {
    BooleanField,
    ChipField, NumberField,
    ReferenceArrayField,
    Show,
    SimpleShowLayout,
    SingleFieldList,
    TextField,ImageField
} from 'react-admin';
import resource from "../../config/resource";

const RecipeCatsShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="name" />
                <ImageField source={"selectImage"}/>
                <ImageField source={"deselectImage"}/>
                <TextField source={"ratio"}/>
                <NumberField source={"horizontalOrder"}/>
                <NumberField source={"verticalOrder"}/>
                <TextField source={"type"}/>
                <ReferenceArrayField
                    source="tags"
                    reference={resource.foodTag}
                >
                    <SingleFieldList linkType={false}>
                        <ChipField className="packageFoods" source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <BooleanField addLabel source="deleted" />
            </SimpleShowLayout>
        </Show>
    );
};

export default RecipeCatsShow;