import {Box, Chip} from "@material-ui/core";
import get from 'lodash/get';

const TextArrayField = ({ record, source,emptyText }) => (
    <>
        {get(record,source)?get(record,source).map(item => <Box display={"inline-block"} component={"div"} m={'4px'} key={item}><Chip label={item} key={item} /></Box>) : emptyText}
    </>
)
TextArrayField.defaultProps = { addLabel: true };

export default TextArrayField;