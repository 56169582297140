import React, {Children, cloneElement, isValidElement} from 'react';
import {Labeled} from "react-admin";
import {Box, Grid} from "@material-ui/core";
import classnames from "classnames";
const WithPropsAndLabels = ({children,...props}) => {
    return (
        React.Children.map(children,child => addLabelsAndClonePropsToChildren(child,props))
    );
};

export default WithPropsAndLabels;

export const addLabelsAndClonePropsToChildren = (element, props) => {
    if (isLayoutComponent(element)) {
        return cloneElement(element, {
            children: Children.map(element.props.children, child => addLabelsAndClonePropsToChildren(child, props)),
        })
    }
    if (isInputComponent(element)) {
        return (
            <div
                key={element.props.source}
                className={classnames(
                    `ra-input ra-input-${element.props.source}`,
                    element.props.className
                )}
            >
                {cloneElement(element, {
                    record: props.record,
                    resource: props.resource,
                    basePath: props.basePath,
                    source:props.source ? `${props.source}.${element.props.source}` : element.props.source
                })}
            </div>
        )
    }
    if (isFieldComponent(element)) {
        return (
            <div
                key={element.props.source}
                className={classnames(
                    `ra-field ra-field-${element.props.source}`,
                    element.props.className
                )}
            >
                <Labeled
                    record={props.record}
                    resource={props.resource}
                    basePath={props.basePath}
                    label={element.props.label}
                    source={element.props.source}
                    disabled={false}
                    fullWidth={element.props.fullWidth}
                >
                    {element}
                </Labeled>
            </div>
        )
    }
    if (isValidElement(element)) {
        return cloneElement(element, {
            record: props.record,
            resource: props.resource,
            // formValues:props.formValues
        })
    }
}

const isLayoutComponent = (element) => {
    return (typeof element?.type === 'string' || element?.type === React.Fragment) || (isValidElement(element) && [Grid, Box].includes(element.type))
}
const isFieldComponent = (element) => {
    return isValidElement(element) && element.props?.addLabel;
}
const isInputComponent = (element) => {
    return isValidElement(element) && !element.props?.addLabel && element.props?.source
}