import {useNotify, useTranslate} from "react-admin";

const useCustomNotify = () => {
    const translate = useTranslate();
    const notify = useNotify();

    return (data,type) => {
        if(typeof data ==='string')
            notify(data,type);
        else if(typeof data ==='object'){
            if(data.statusCode===200 || data.success)
                notify(data.message || translate("api.success"),"success")
            else
                notify(data.message || translate("api.error"),"error")
        }
    };
};

export default useCustomNotify;