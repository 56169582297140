import React from 'react';
import GroceryForm from "./GroceryForm";
import {Edit} from 'react-admin';

const GroceryCategoryEdit = (props) => {
    return (
        <Edit {...props}>
            <GroceryForm/>
        </Edit>
    );
};

export default GroceryCategoryEdit;