import React from 'react';
import {
    BooleanField,
    Datagrid,
    Filter,
    ImageField,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
} from 'react-admin';
import resource from "../../config/resource";
import {toCsv} from "../../utils/utils";

const RecipeList = (props) => {
    return (
        <List {...props} filters={<RecipeFilter/>} exporter={exporter} bulkActionButtons={false}>
            <Datagrid rowClick="show">
                <ImageField source="image" label="Image"/>
                <ReferenceField source="food" reference={resource.food}>
                    <TextField source="name"/>
                </ReferenceField>
                <NumberField source="time" label="Total Time (min)"/>
                <NumberField source="weight" label="Total Weight (gr)"/>
                <NumberField source="serving"/>
                <TextField source="difficulty"/>
                <TextField source="status"/>
                <BooleanField addLabel source="deleted"/>
            </Datagrid>
        </List>
    );
};

const RecipeFilter = (props) => (
    <Filter {...props}>
        <TextInput
            label="Search by Food Name"
            source="foodName"
            alwaysOn
        />
        <SelectInput source="status" choices={[
            {id: "nutritionVerification", name: "Nutrition Verification"},
            {id: "rejected", name: "Rejected"},
            {id: "contentVerification", name: "Content Verification"},
            {id: "verified", name: "Verified"},
        ]} label="Status" resettable alwaysOn/>
        <ReferenceInput
            label="Filter by Categories"
            source="category"
            reference={resource.recipeCategory}
        >
            <SelectInput label="Categories" source="name"/>
        </ReferenceInput>
    </Filter>
);

const exporter = async (recipes, fetchRelatedRecords, dataProvider) => {
    const validRecipes = recipes.filter(recipe => (recipe.deleted === false && recipe.verified === true && recipe.contentVerified === true))
    const foods = await fetchRelatedRecords(validRecipes, 'food', resource.food)
    const data = validRecipes.map(recipe => {
        const {food, ...recipesForExport} = recipe; // omit food
        recipesForExport.deepLink = `https://bentoapp.org/deeplink/recipe?id=${recipe._id}`
        recipesForExport.imagePath = `${recipe.imagePath.replace(".webp", "-original.png")}`
        recipesForExport.food = foods[recipe.food]?.name;
        return recipesForExport;
    });
    toCsv(
        data,
        'recipes',
        ['food', 'imagePath', 'deepLink']
    );
}

export default RecipeList;