import React from 'react';
import {
    ArrayField,
    BooleanField,
    Datagrid, FileField,
    ImageField, NumberField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import resource from "../../config/resource";
import FoodEditButton from "./FoodEditButton";

const RecipeShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="status"/>
                <BooleanField addLabel source="deleted"/>
                <BooleanField addLabel source="free"/>
                <ImageField source="image" label="Image"/>
                <FileField source={"video"} label={"video"} title={"فایل"}/>
                <FileField emptyText={"-"} source={"videoLowRes"} label={"video low resolution"} title={"فایل"}/>
                <ReferenceField source="food" reference={resource.food} label="نام">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="newFood"/>
                <NumberField source="time" title="Total Time (min)"/>
                <NumberField source="weight" title="Total Weight (gr)"/>
                <NumberField source="serving" title="Serving"/>
                <TextField source="difficulty"/>
                <ArrayField source="ingredients">
                    <Datagrid rowClick="">
                        <FoodEditButton/>
                        <ReferenceField source="food" reference={resource.food} label="Icon" link={false}>
                            <ImageField source="icon"/>
                        </ReferenceField>
                        <ReferenceField source="food" reference={resource.food} link={true}>
                            <TextField source="name"/>
                        </ReferenceField>
                        <TextField source="amount" label="amount(gr)"/>
                        <ReferenceField source="unit" reference={resource.foodUnit} link={false}>
                            <TextField source="name"/>
                        </ReferenceField>
                    </Datagrid>
                </ArrayField>
                <ArrayField source="instructions">
                    <Datagrid>
                        <TextField source="text" fullWidth/>
                    </Datagrid>
                </ArrayField>
                <TextField source="description" fullWidth/>
            </SimpleShowLayout>
        </Show>
    );
};

export default RecipeShow;