import React from 'react';
import {Edit, SimpleForm, TextInput} from 'react-admin';

const PackageCalorieEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="show">
                <TextInput source="amount" />
            </SimpleForm>
        </Edit>
    );
};

export default PackageCalorieEdit;