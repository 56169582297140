import React from 'react';
import {Layout,useAuthenticated} from 'react-admin';
import Menu from "../menu/Menu";


const CustomLayout = (props) => {
    useAuthenticated();
    return (
        <Layout {...props} menu={Menu}/>
    );
};

export default CustomLayout;