import {useSelector} from "react-redux";

const useResource = (resource) => {
    let data = useSelector(state => state.admin.resources[resource].data);

    const getResources = (ids) => {
        if (!Array.isArray(ids)) {
            ids = [ids];
        }
        const result = [];
        ids.forEach(id => {
            data[id] && result.push(data[id])
        })
        return result;
    }

    return (
        getResources
    );
};

export default useResource;