import React from 'react';
import {Button, useMutation, useRefresh} from "react-admin";
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import resource from "../../config/resource";
import CustomForm from "../../components/form/CustomForm";
import useCustomNotify from "../../components/useCustomNotify";
import Upload from "../../components/inputs/Upload";
import useGetResource from "../../components/resource/useGetResource";

const FoodEditButton = React.memo((props) => {
    const [open, setOpen] = React.useState(false);
    const notify = useCustomNotify();
    const refresh = useRefresh();
    const food = useGetResource(resource.food, foods => foods[props.record.food]);
    const [updateFood, {loading}] = useMutation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const onUpdateFood = (values) => {
        updateFood({
            type: 'update',
            payload: {id: values._id, data: values},
            resource: resource.food
        }, {
            onFailure: notify,
            onSuccess: (res) => {
                notify(res);
                handleClose()
                refresh();
            }
        })
    }

    return (
        <>
            <Button label="Edit Food Icon" startIcon={<EditIcon/>} onClick={handleClickOpen}/>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{food?.name}</DialogTitle>

                <CustomForm initialValues={food} save={onUpdateFood} saving={loading} toolbar={null}>
                    {(_,form) => (
                        <>
                            <DialogContent>
                                <Upload segment={"food"} type={"image"} source={"icon"}/>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary" label={"Cancel"}/>
                                <Button onClick={form.submit} color="primary" label={"ذخیره"}/>
                            </DialogActions>
                        </>
                    )}
                </CustomForm>
            </Dialog>
        </>

    )
});

export default FoodEditButton;