import {
    httpClient,
} from "../config/client";
import toFormData from 'json-form-data';

class Service {
    constructor(URL) {
        this.URL = URL;
    }
    static upload(params){
        const {file,onUploadProgress,...parameters} = params;
        return httpClient({URL:"/upload",data: toFormData({file:params.file}),params: parameters,method:"POST",onUploadProgress})
    }
    getList(params) {
        return httpClient({URL:this.URL, params}).then(res => ({...res,data:res.data.map(d => this.transformResponse(d))}))
    }
    getOne(params) {
        return httpClient({URL:`${this.URL}/${params.id}`}).then(res => ({...res,data:this.transformResponse(res.data)}))
    }
    getMany(params) {
        return httpClient({URL:this.URL, params: {filter: {id: params.ids},pagination:{perPage:1000,page:1}}}).then(res => ({...res,data:res.data.map(d => this.transformResponse(d))}))
    }
    getManyReference(params) {
        return httpClient({
            URL:this.URL,
            params: {
                pagination: params.pagination,
                sort: params.sort,
                filter: {...params.filter, [params.target]: params.id}
            }
        }).then(res => ({...res,data:res.data.map(d => this.transformResponse(d))}))
    }
    update(params) {
        return httpClient({URL:`${this.URL}/${params.id}`, method: 'PUT', data: this.transformRequest(params.data)}).then(res => ({...res,data:this.transformResponse(res.data)}))
    }
    create(params) {
        return httpClient({URL:this.URL, method: 'POST', data: this.transformRequest(params.data)}).then(res => ({...res,data:this.transformResponse(res.data)}))
    }
    delete(params) {
        return httpClient({URL:`${this.URL}/${params.id}`, method: 'DELETE', data: params.data})
    }
    updateMany(params) {
        return httpClient({URL:`${this.URL}/bulk`, method: 'PUT', data: params})
    }
    transformResponse(res){
        return res;
    }
    transformRequest(req){
        return req;
    }
}

export default Service;