import React from 'react';
import {FormTab} from "react-admin";
import WithPropsAndLabels from "../WithPropsAndLabels";
import {useForm, useFormState} from "react-final-form";

const CustomFormTab = ({children,label,...props}) => {
    const form = useForm();
    const formState = useFormState();
    return (
        <FormTab label={label} {...props}>
            <WithPropsAndLabels>
                {typeof children ==='function' ? children(formState,form) : children}
            </WithPropsAndLabels>
        </FormTab>
    );
};

export default CustomFormTab;