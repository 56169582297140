import React, {useCallback, useEffect, useMemo} from 'react';
import {useListContext, Pagination as RaPagination, PaginationActions} from 'react-admin';
import {Box, Input} from "@material-ui/core";
import debounce from 'lodash/debounce';

const CustomPagination = () => {
    return <RaPagination actions={CustomPaginationActions}/>
};

const CustomPaginationActions = (props) => {
    const {perPage, total, setPage} = useListContext();
    const nbPages = Math.ceil(total / perPage) || 1;
    const handleChange = useCallback((e) => {
        if (e.target.value <= nbPages)
            setPage(e.target.value);
    },[nbPages,setPage])
    const debouncedHandler = useMemo(() => debounce(handleChange, 1000), [handleChange]);
    useEffect(() => {
        return () => {
            debouncedHandler.cancel();
        }
    }, [debouncedHandler]);
    return (
        <>
            <PaginationActions {...props}/>
            <Box width={100} ml={3}>
                <Input type={"number"} value={undefined} onChange={debouncedHandler} placeholder={"صفحه"}/>
            </Box>
        </>
    )
}

export default CustomPagination;