import {useSelector} from "react-redux";

const useSearchResource = (resource) => {
    let data = useSelector(state => state.admin.resources[resource].data);

    const search = (filter) => {
        const result = []
        for (let id in data) {
            let isValid = true;
            for (let key in filter) {
                if(data[id][key]!==filter[key]){
                    isValid=false;
                    break;
                }
            }
            if(isValid)
                result.push(data[id])
        }
        return result;
    }
    return (
       search
    );
};

export default useSearchResource;