import qs from "qs";
import jsonExport from 'jsonexport/dist';
import {downloadCSV} from 'react-admin';
export function toToman(price){
    price = price/10;
    return numberWithCommas(price)
}

export function numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getQueryParam(key){
    return qs.parse(window.location.hash.split('?').pop(), {ignoreQueryPrefix: true})[key];
}

export function toCsv(data,filename="export",order){
    jsonExport(data, {
        headers: order,
        order
    }, (err, csv) => {
        downloadCSV(`\ufeff${csv}`,filename);
    });
}
export function toEnglishNumber(num){
    let result = num;
    result = result.replaceAll('۰', '0');
    result = result.replaceAll('۱', '1');
    result = result.replaceAll('۲', '2');
    result = result.replaceAll('۳', '3');
    result = result.replaceAll('۴', '4');
    result = result.replaceAll('۵', '5');
    result = result.replaceAll('۶', '6');
    result = result.replaceAll('۷', '7');
    result = result.replaceAll('۸', '8');
    result = result.replaceAll('۹', '9');
    return result;
}