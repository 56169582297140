import React from 'react';
import {BooleanField, Show, SimpleShowLayout, TextField} from 'react-admin';

const DietTypeCategoryShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="name" />
                <BooleanField addLabel source="completed" />
                <BooleanField addLabel source="deleted" />
            </SimpleShowLayout>
        </Show>
    );
};

export default DietTypeCategoryShow;