import React from 'react';
import {Edit} from 'react-admin';
import FoodForm from "./FoodForm";

const FoodEdit = (props) => {
    return (
        <Edit {...props} mutationMode={"pessimistic"}>
            <FoodForm/>
        </Edit>
    );
};

export default FoodEdit;