import React from 'react';
import {
    BooleanField,
    ReferenceArrayField,
    SimpleShowLayout,
    TextField,
    SingleFieldList,
    ChipField,
    Show,
    DateField
} from 'react-admin';
import resource from "../../config/resource";

const GroceryCategoryShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <BooleanField addLabel source={"deleted"}/>
                <TextField source={"name"}/>
                <TextField source={"order"}/>
                <ReferenceArrayField source={"tags"} reference={resource.foodTag}>
                    <SingleFieldList>
                        <ChipField source="name"/>
                    </SingleFieldList>
                </ReferenceArrayField>
                <DateField locales={"fa-IR"} source={"createdAt"}/>
                <DateField locales={"fa-IR"} source={"updatedAt"}/>
            </SimpleShowLayout>
        </Show>

    );
};

export default GroceryCategoryShow;