import React from 'react';
import {Create} from 'react-admin';
import PackageForm from "./PackageForm";

const PackageCreate = (props) => {
    return (
        <Create {...props}>
            <PackageForm/>
        </Create>
    );
};

export default PackageCreate;