import persianMessages from 'ra-language-farsi';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import resource from "./resource";

const messages = {
    fa: {
        resources: {
            [resource.user]: {
                name: 'کاربر |||| کاربران',
                fields: {}
            }
        },
        sex: {
            female: 'زن',
            male: 'مرد',
        },
        market: {
            android: 'اندروید',
            pwa: 'وب اپ',
            ios: 'iOS',
            bazaaar: 'بازار',
            myket: 'مایکت',
            play: 'گوگل پلی',
            sam:'sam'
        },
        status: {
            purchased: 'خرید موفق',
            pending: 'در حال انتظار',
            NOK: 'خرید ناموفق',
            // pending : "انتخاب نشده",
            consumed : "رعایت شده",
            passed : "رعایت نشده",
        },
        goal: {
            weightFix: 'تثبیت وزن',
            weightLoss: 'کاهش وزن',
            weightGain: 'افزایش وزن',
            pregnancy: 'بارداری',
            breastfeeding: 'شیردهی',
            preDefined: 'برنامه‌ی غذایی خاص'
        },
        difficulty: {
            easy: 'آسان',
            medium: 'متوسط',
            hard: 'سخت',
            none: 'هیج‌کدام'
        },
        activity: {
            sedentary: "ناتوان حرکتی",
            light: "کم تحرک",
            moderate: "متوسط",
            active: "زیاد",
            extreme: "خیلی زیاد",
        },
        meal: {
            breakfast: "صبحانه",
            lunch: "نهار",
            dinner: "شام",
            snack1: "میان‌وعده اول",
            snack2: "میان‌وعده دوم",
            snack3: "میان‌وعده سوم",
            snack4:"میان‌وعده چهارم"
        },
        api: {
            success: 'عملیات با موفقیت انجام شد',
            error: 'خطا در برقراری ارتباط دوباره امتحان کنید.'
        },
        ...persianMessages
    }
}
messages.fa.ra.notification.logged_out = "نشست کاربری شما به پایان رسیده‌است، لطفا دوباره وصل شوید.";

const i18nProvider = polyglotI18nProvider(() => messages['fa'], 'fa', {
    allowMissing: true,
    onMissingKey: (key, _, __) => key
});
export default i18nProvider;