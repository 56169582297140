import React from 'react';
import {
    ReferenceArrayInput,
    BooleanInput,
    SimpleForm,
    TextInput,
    AutocompleteArrayInput,
    required,
    NumberInput
} from 'react-admin';
import resource from "../../config/resource";

const GroceryForm = (props) => {
    return (
        <SimpleForm {...props} redirect={"list"}>
            <BooleanInput source={"deleted"}/>
            <TextInput source={"name"} validate={required()}/>
            <NumberInput source={"order"}/>
            <ReferenceArrayInput label="Tags" reference={resource.foodTag} source="tags" validate={required()}>
                <AutocompleteArrayInput />
            </ReferenceArrayInput>
        </SimpleForm>
    );
};

export default GroceryForm;