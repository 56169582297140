import React from 'react';
import {Create, NumberInput, SimpleForm, TextInput} from 'react-admin';

const MealCategoryCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <TextInput source="name" />
                <NumberInput source="importance" />
            </SimpleForm>
        </Create>
    );
};

export default MealCategoryCreate;