import React from 'react';
import {
    SelectArrayInput,
    BooleanInput,
    useQueryWithStore,
    Button,
    required,
    useMutation, useRefresh
} from "react-admin";
import resource from "../../config/resource";
import CustomForm from "../../components/form/CustomForm";
import {Dialog, DialogContent,DialogActions} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import useCustomNotify from "../../components/useCustomNotify";

const UpdateTagsButton = (props) => {
    const notify = useCustomNotify();
    const refresh = useRefresh();
    const [open, setOpen] = React.useState(false);
    const {data: tags} = useQueryWithStore({
        type: 'getList',
        resource: resource.foodTag,
        payload: null
    })
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [updateMany] = useMutation();
    const onUpdate = (values) => {
        updateMany({
            type:"updateMany",
            resource:resource.food,
            payload:{
                ids:props.selectedIds,
                data:values
            }
        },{
            onSuccess:res => {
                notify(res);
                handleClose();
                refresh()
            },
            onFailure:notify
        })
    }
    return (
        <>
            <Button label="ویراش تگ ها" startIcon={<EditIcon/>} onClick={handleClickOpen}/>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <CustomForm toolbar={null} save={onUpdate}>
                    {
                        (_,form) => (
                            <>
                                <DialogContent>
                                    <BooleanInput source={"checked"} defaultValue={false}/>
                                    <SelectArrayInput source={"tags"} choices={tags} validate={required()}/>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary" label={"Cancel"}/>
                                    <Button onClick={form.submit} label={"update"}/>
                                </DialogActions>
                            </>
                        )
                    }
                </CustomForm>
            </Dialog>
        </>
    );
};

export default UpdateTagsButton;