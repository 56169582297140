    import React from 'react';
    import {
    BooleanField,
    ChipField,
    Datagrid,
    List,
        NumberField,
    ReferenceArrayField,
    SingleFieldList,
    TextField,ImageField
} from 'react-admin';
    import resource from "../../config/resource";

    const RecipeCatsList = (props) => {
        return (
            <List {...props}>
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    <ImageField source={"selectImage"}/>
                    <ImageField source={"deselectImage"}/>
                    <TextField source={"ratio"}/>
                    <NumberField source={"horizontalOrder"}/>
                    <NumberField source={"verticalOrder"}/>
                    <TextField source={"type"}/>
                    <ReferenceArrayField
                        source="tags"
                        reference={resource.foodTag}
                    >
                        <SingleFieldList linkType={false}>
                            <ChipField className="packageFoods" source="name" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <BooleanField addLabel source="deleted" />
                </Datagrid>
            </List>
        );
    };

    export default RecipeCatsList;