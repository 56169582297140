import { createTheme, jssPreset } from "@material-ui/core/styles";
import { defaultTheme as raTheme } from "react-admin";
import { create } from "jss";
import rtl from "jss-rtl";
import { green, grey } from "@material-ui/core/colors";

const defaultTheme = createTheme();
export const theme = createTheme({
  ...raTheme,
  direction: "rtl",
  typography: {
    fontFamily: "IRANSans",
  },
  palette: {
    secondary: {
      light: "#5C5EBF",
      main: "#5C5EBF",
      dark: "#484A9C",
      contrastText: "#fff",
    },
    success: {
      main: green[500],
      contrastText: "#ffffff",
    },
  },
  overrides: {
    MuiTablePagination: {
      spacer: {
        flex: "none",
      },
    },
    RaDatagrid: {
      headerCell: {
        color: grey[600],
        fontSize: defaultTheme.typography.caption.fontSize,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40,
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: "48px!important",
      },
    },
    RaMenuItemLink: {
      root: {
        color: "inherit",
          },
      active: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    MuiFormControl: {
      root: {
        minWidth: 256,
      },
    },
    RaSelectInput: {
      input: {
        minWidth: 256,
      },
    },
    RaCheckboxGroupInput: {
      label: {
        transform: "none",
      },
    },
    RaSimpleFormIterator: {
      root: {
        marginTop: 20,
      },
    },
  },
});
export const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
